<template>
  <div class="w-full px-3 mb-2">
    <div v-if="type == 'radio'">
      <div class="flex items-center">
        <input
          type="radio"
          :name="name"
          class="mx-2"
          :id="id"
          :checked="modelValue == value"
          :disabled="disabled"
          @input="$emit('update:modelValue', value)"
        />
        <label
          :for="id"
          class="inline-block uppercase tracking-wide text-gray-700 text-xs font-bold cursor-pointer"
          >{{ label }}</label
        >
      </div>
    </div>
    <div v-if="type == 'checkbox'" class="flex items-start">
      <label
        class="inline-block uppercase tracking-wide text-gray-700 text-xs font-bold cursor-pointer"
        :class="{ 'sr-only': hideLabel }"
      >
        <input
          type="checkbox"
          class="bg-gray-200 rounded border focus:border-gray-600 mr-1"
          :id="id"
          :checked="modelValue == (value || true)"
          :disabled="disabled"
          @input="
            $emit('update:modelValue', $event.target.checked && (value || true))
          "
        />
        {{ label }}
      </label>
    </div>
    <label
      v-if="type !== 'checkbox' && type !== 'radio'"
      class="inline-block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 cursor-pointer"
      :for="id"
      :class="{ 'sr-only': hideLabel }"
    >
      {{ label }}
    </label>
    <input
      v-if="
        !type ||
        type === 'number' ||
        type === 'date' ||
        type === 'time' ||
        type === 'text' ||
        type === 'email'
      "
      :type="type || 'text'"
      :disabled="disabled"
      class="block w-full bg-gray-200 rounded text-md text-gray-500 p-2 focus:bg-white border focus:border-gray-600 appearance-none focus:outline-none"
      :value="value || modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :id="id"
      :maxlength="maxLength"
    />
    <textarea
      v-if="type === 'textarea'"
      :disabled="disabled"
      class="w-full bg-gray-200 rounded text-md text-gray-500 p-2 focus:bg-white border focus:border-gray-600 appearance-none focus:outline-none"
      :value="value || modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :id="id"
    />
    <select
      v-else-if="type === 'select'"
      @change="$emit('update:modelValue', $event.target.value)"
      class="block w-full bg-gray-200 rounded text-md text-gray-500 text-md rounded p-2 overflow-hidden whitespace-nowrap"
      :class="[error && 'ring-1 ring-red-500']"
      :disabled="!options || disabled"
      :id="id"
    >
      <option :value="''" v-if="nullable || nullOption">
        {{ nullOption }}
      </option>
      <option
        v-for="option in listOptions"
        :value="option.value"
        :key="String(option.value)"
        :selected="modelValue === option.value"
      >
        {{ option.label }}
      </option>
    </select>
    <combo-box
      v-else-if="type == 'combo'"
      :model-value="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
      :options="listOptions"
      :multiple="multiple"
      :disabled="disabled"
    />
    <multi-select
      v-else-if="type == 'multi'"
      :model-value="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
      :options="options"
      :multiple="multiple"
      :placeholder="placeholder"
      :disabled="disabled"
      mode="tags"
      searchable
    />
  </div>
</template>

<script setup>
import { computed, onMounted, watch } from 'vue'
import ComboBox from './ComboBox.vue'
import MultiSelect from './MultiSelect.vue'

const props = defineProps([
  'modelValue',
  'label',
  'options',
  'type',
  'value',
  'multiple',
  'placeholder',
  'nullable',
  'nullOption',
  'disabled',
  'hideLabel',
  'error',
  'maxLength',
  'checked',
  'name',
])
const emit = defineEmits(['update:modelValue'])

const id = computed(() => props.label.replace(/^[a-zA-Z0-9]/, '_'))

const listOptions = computed(() => {
  if (!props.options) return null
  if (props.options.length < 1) return props.options
  if (props.options[0].value || props.options[0].label) return props.options

  return props.options.map((o) => ({
    value: o,
    label: o,
  }))
})

function selectFirstOptionIfNeeded() {
  if (
    props.type === 'select' &&
    props.options?.length &&
    !props.nullable &&
    !props.nullOption &&
    !props.options.find((o) => o.value == props.modelValue)
  ) {
    emit('update:modelValue', props.options[0].value)
  }
}

// if not nullable and no option is selected, select the first option
watch(props, selectFirstOptionIfNeeded)
onMounted(selectFirstOptionIfNeeded)
</script>
