import { DateTime } from 'luxon'
import { useAuthStore } from '@/auth'

export function localizedDate(val) {
  return DateTime.fromISO(val, { locale: 'nl-NL' }).toLocaleString(
    DateTime.DATE_MED
  )
}

export function fullLocalizedDate(val) {
  return DateTime.fromISO(val, { locale: 'nl-NL' }).toLocaleString(
    DateTime.DATETIME_FULL
  )
}

export function luxonFromTicketFormat(val) {
  return DateTime.fromFormat(val, 'd-MMM-yyyy hh:mm:ss', {
    locale: 'en',
    zone: 'utc',
  })
}

export function luxonFromTicketFormatLocalTime(val) {
  let format = 'dd LLL yyyy hh:mm:ss'
  const weekdayFormatRegex = /^\w{3} \w{3} \d{2} \d{4} \d{2}:\d{2}$/ // Fri Nov 04 2022 13:25
  const customFromatRegex = /^\w{3} \d{2} \d{4} \d{2}:\d{2}:\d{2}$/ // Nov 04 2022 00:00:00
  const noTimeDateFormatRegex = /^\d{2}-\w{3}-\d{4}$/ // 08-Jun-2021
  const timeDateDashesFormatRegex = /^\d{2}-\w{3}-\d{4} \d{2}:\d{2}:\d{2}$/ // 08-Jun-2021 00:00:00
  const timeDateNoSecDashesFormatRegex = /^\d{2}-\w{3}-\d{4} \d{2}:\d{2}$/ // 08-Jun-2021 00:00
  if (timeDateDashesFormatRegex.test(val)) {
    format = 'd-MMM-yyyy hh:mm:ss'
  } else if (timeDateNoSecDashesFormatRegex.test(val)) {
    format = 'd-MMM-yyyy hh:mm'
  } else if (weekdayFormatRegex.test(val)) {
    format = 'ccc LLL dd yyyy HH:mm'
  } else if (customFromatRegex.test(val)) {
    format = format = 'LLL dd yyyy HH:mm:ss'
  } else if (noTimeDateFormatRegex.test(val)) {
    format = 'dd-MMM-yyyy'
  }
  return DateTime.fromFormat(val, format, { zone: 'utc' }, { locale: 'en' })
}

export function shortLocalizedDateTime(val) {
  return DateTime.fromISO(val, { locale: 'nl-NL' }).toFormat('d MMM h:mm', {
    locale: 'nl-NL',
  })
}

export function shortLocalizedDateTimeFromTicketFormat(val) {
  return luxonFromTicketFormatLocalTime(val)
    .setZone('Europe/Amsterdam')
    .toLocaleString(DateTime.DATETIME_MED, { locale: 'nl-NL' })
}

export function shortLocalizedDateFromTicketFormat(val) {
  return luxonFromTicketFormatLocalTime(val)
    .setZone('Europe/Amsterdam')
    .toLocaleString(DateTime.DATE_MED, { locale: 'nl-NL' })
}

export function timeFromISO(val) {
  return DateTime.fromISO(val, { locale: 'nl-NL' }).toLocaleString(
    DateTime.TIME_24_SIMPLE,
    { locale: 'nl-NL' }
  )
}

export function longLocalizedDateTimeFromISO(val) {
  return DateTime.fromISO(val, { locale: 'nl-NL' }).toFormat(
    'EEEE d MMMM yyyy HH:mm',
    { locale: 'nl-NL' }
  )
}

export function getCookie(name) {
  // Get name followed by anything except a semicolon
  var cookiestring = RegExp(name + '=[^;]+').exec(document.cookie)
  // Return everything after the equal sign, or an empty string if the cookie name not found
  return decodeURIComponent(
    !!cookiestring ? cookiestring.toString().replace(/^[^=]+./, '') : ''
  )
}

export async function fetchFromApi(path, options = {}) {
  const headers = {
    Authorization: `Bearer ${getCookie('access_token')}`,
    'Content-Type': 'application/json',
    ...(options.headers || {}),
  }

  return fetchFromUrl(path, { ...options, headers })
}

export async function fetchFromUrl(url, options = {}) {
  const auth = useAuthStore()
  const headers = options.headers || {}
  if (options.body) {
    headers['Content-Type'] = 'application/json'
    options.body = JSON.stringify(options.body)
  }
  const result = await fetch(url, { ...options, headers })

  if (result.status === 401) {
    auth.clearUserData()
    throw new Error('Unauthorized request', result.error)
  } 
  else if (result.ok) {
    const data = await result.json()
    if (data.status && data.status === 'error') {
      throw new Error(`Error: ${data.message}`)
    }
    else {
      return data
    }
  } 
  else if (result.headers.get('content-type') === 'application/json') {
    throw await result.json()
  } 
  else {
    console.error('Fetch error', result)
    throw new Error('Invalid response from API')
  }
}

export function toSnakeCase(str) {
  return str
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_')
}

export function ticketStatusLocalDateTime(ticket) {
  const status = ticket.data.status
  const last = status[status.length - 1]
  // console.log(Object.keys(last).find(s => s.endsWith('_at')))
  const key = Object.keys(last).find((s) => s.endsWith('_at'))
  if (!key) return

  return shortLocalizedDateTimeFromTicketFormat(last[key])
}

export function ticketStatusComment(ticket) {
  const status = ticket.data.status
  return status[status.length - 1].comment
}

export function sum(list) {
  if (!list) return 0
  return list.reduce((a, b) => Number(a) + b, 0)
}

export function monthYear(val) {
  return val
    .setZone('Europe/Amsterdam')
    .toFormat('MMMM yyyy', { locale: 'nl-NL' })
}
