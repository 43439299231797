<template>
  <Combobox
    v-if="options"
    :model-value="modelValue"
    @update:modelValue="(v) => $emit('update:modelValue', v)"
    :multiple="multiple"
  >
    <div class="relative mt-1 w-full">
      <div class="block w-full bg-gray-200 rounded">
        <ComboboxInput
          class="block w-full bg-gray-200 rounded text-md text-gray-500 p-2 focus:bg-white border focus:border-gray-600 appearance-none focus:outline-none"
          :displayValue="
            (option) =>
              multiple ? option?.map((o) => o.label).join(', ') : option?.label
          "
          @change="query = $event.target.value"
        />
        <ComboboxButton
          class="absolute inset-y-0 right-0 flex items-center pr-2"
          v-if="(!query && empty) || multiple"
        >
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>
        <button
          v-else
          class="absolute inset-y-0 right-0 flex items-center pr-2"
          @click="$emit('update:modelValue', multiple ? [] : null)"
        >
          <XMarkIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </button>
      </div>
      <TransitionRoot
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        @after-leave="query = ''"
      >
        <ComboboxOptions
          class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <div
            v-if="filteredOptions.length === 0 && query !== ''"
            class="relative cursor-default select-none py-2 px-4 text-gray-700"
          >
            Nothing found.
          </div>

          <ComboboxOption
            v-for="option in filteredOptions"
            as="template"
            :key="option.value"
            :value="option"
            v-slot="{ selected, active }"
          >
            <li
              class="relative cursor-pointer select-none py-2 pl-10 pr-4"
              :class="{
                'bg-gray-300 text-white': active,
                'text-gray-900': !active,
              }"
            >
              <span
                class="block truncate"
                :class="{ 'font-medium': selected, 'font-normal': !selected }"
              >
                {{ option.label }}
              </span>
              <span
                v-if="selected"
                class="absolute inset-y-0 left-0 flex items-center pl-3"
                :class="{ 'text-white': active, 'text-teal-600': !active }"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </TransitionRoot>
    </div>
  </Combobox>
</template>

<script setup>
import { ref, computed } from 'vue'
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from '@headlessui/vue'
import {
  CheckIcon,
  XMarkIcon,
  ChevronUpDownIcon,
} from '@heroicons/vue/20/solid'

const props = defineProps(['options', 'modelValue', 'multiple'])
defineEmits(['update:modelValue'])

const query = ref('')

const filteredOptions = computed(() =>
  query.value === ''
    ? props.options
    : props.options.filter((option) =>
        option.label
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.value.toLowerCase().replace(/\s+/g, ''))
      )
)

const empty = computed(() =>
  props.multiple ? props.modelValue.length === 0 : !props.modelValue
)
</script>
