import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import { VueQueryPlugin } from '@tanstack/vue-query'

import * as Sentry from '@sentry/vue'

import './style.css'
import { createPinia } from 'pinia'

const app = createApp(App)
const pinia = createPinia()

Sentry.init({
  app,
  dsn: 'https://0cc4ed18dd92de18e80feea50364a326@o4508244642168832.ingest.de.sentry.io/4508244654620752',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  environment: window.location.hostname,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^\/api\//],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.use(router)
app.use(VueQueryPlugin, { options: { staleTime: 60 * 5 * 1000 } })
app.use(pinia)

app.mount('#app')
