<template>
  <div class="bg-gray-200 h-screen pt-4 sm:pt-16">
    <div
      class="border-y sm:border-x border-gray-500 sm:rounded-md max-w-lg mx-auto bg-gray-50"
    >
      <div
        class="bg-[#486f14] text-white p-2 tracking-wider text-xs drop-shadow"
      >
        Login voor Ecorus Tickets
      </div>
      <div class="p-2">
        <template v-if="!form.otpSend">
          <div>
            Je logt in met je emailadres. We sturen je vervolgens een eenmalig
            wachtwoord, dat je weer op deze pagina invult. Zo houden we alles
            veilig!
          </div>
          <form @submit.prevent="createOTP">
            <div class="mt-3 items-center flex flex-col gap-2">
              <FormField
                v-model="form.email"
                label="E-mail"
                class="px-0 mb-0"
                type="email"
              />              
              <FormField
                v-model="form.rememberEmail"
                label="Onthoud mijn e-mail"
                type="checkbox"
                class="px-0 py-0"
              />
              <TextButton
                class="bg-gray-400 hover:bg-gray-300"
                @click="createOTP"
                :loading="createOTPLoading"
                >Verstuur</TextButton
              >
            </div>
          </form>
        </template>
        <template v-else>
          <div>
            De OTP code is verstuurd. Zie je hem niet? Check dan je spam-mail.
            Nog steeds niet?
            <a href="#" class="underline" @click="createOTP"
              >Stuur hem opnieuw</a
            >. <Spinner class="w-4 h-4 inline" v-if="createOTPLoading" />
          </div>
          <form @submit.prevent="createJWT">
            <div class="mt-3 items-center flex flex-col gap-2">
              <FormField
                v-model="form.otp"
                label="OTP"
                class="px-0 mb-0"
                type="text"
              />
              <TextButton
                class="bg-blue-400 hover:bg-blue-300"
                @click="createJWT"
                :loading="createJWTLoading"
                >Inloggen</TextButton
              >
            </div>
          </form>
        </template>
        <div
          class="mt-5 p-3 bg-red-200 border border-red-400"
          v-if="form.error"
        >
          {{ form.error }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import FormField from '@/components/FormField.vue'
import TextButton from '@/components/TextButton.vue'
import Spinner from '@/components/Spinner.vue'
import { reactive } from 'vue'
import { useMutation } from '@tanstack/vue-query'
import { fetchFromUrl } from '@/utils'
import { useAuthStore } from '@/auth'

const auth = useAuthStore()

const REMEMBER_EMAIL_KEY = 'ticket-login-remember-email'

const form = reactive({
  email: localStorage.getItem(REMEMBER_EMAIL_KEY) || '',
  rememberEmail: true,
  otp: '',
  otpSend: false,
  error: null,
})

function handleError(e) {
  if (e.detail && e.detail[0]?.msg) {
    form.error = e.detail[0].msg
  } else if (e.message) {
    form.error = e.message
  } else if (e.detail) {
    form.error = e.detail
  } else {
    form.error = e
  }
}

const { mutate: createOTP, isLoading: createOTPLoading } = useMutation({
  mutationFn: async () => {
    form.error = null
    const body = { email: form.email }
    await fetchFromUrl('/auth/api/v1/otps', { method: 'POST', body })

    if (form.rememberEmail) {
      localStorage.setItem(REMEMBER_EMAIL_KEY, form.email)
    }
    else {
      localStorage.removeItem(REMEMBER_EMAIL_KEY)
    }
    form.otpSend = true
  },
  onError: handleError,
})

const { mutate: createJWT, isLoading: createJWTLoading } = useMutation({
  mutationFn: async () => {
    form.error = null
    const body = { email: form.email, otp: form.otp }
    const result = await fetchFromUrl('/auth/api/v1/jwts', {
      method: 'POST',
      body,
    })
    await auth.fetchUserData(result['data']['access_token'])
  },
  onError: handleError,
})
</script>
